import { store } from 'react-notifications-component';

export const onCopySuccess = title => {
  store.addNotification({
    title: title,
    message: "Successfully copied to your clipboard.",
    type: "success",
    insert: "top",
    container: "top-right",
    animationIn: ["animated", "bounceInRight"],
    animationOut: ["animated", "bounceOutRight"],
    dismiss: {
      duration: 1500
    }
  })
};
