import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { onCopySuccess } from '../../helpers';

import './iconCard.scss';

const IconCard = props => {
  const {
    icon,
  } = props;

  return (
    <ul className="icon-card">
      <li>
        <figure className={`si-background si-color ${icon.names[0]}`}/>
      </li>
      <li>
        {icon.names.map((name, index) => (
          <CopyToClipboard text={name} key={index} onCopy={() => onCopySuccess(name)}>
            <p>{name}</p>
          </CopyToClipboard>
        ))}
      </li>
    </ul>
  )
};

export default IconCard;
