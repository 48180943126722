import React from 'react';
import ReactNotification from 'react-notifications-component';

import './notifications.scss';

const Notifications = () => {
  return (
    <ReactNotification />
  );
};

export default Notifications;
