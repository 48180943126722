import React, { useState } from 'react';

import IconSetGrid from '../iconSetGrid';
import ImportSection from '../../components/importSection';
import Header from '../../components/header';
import Notifications from '../../components/notifications';


import '../../assets/scss/index.scss';
import '../../files/sport-icons/import.scss';

const Layout = () => {
  const [search, setSearch] = useState('');

  return (
    <>
      <Header setSearch={setSearch} />
      <main>
        <ImportSection />
        <IconSetGrid search={search} />
      </main>
      <Notifications />
    </>
  );
};

export default Layout;
