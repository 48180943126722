import React from 'react';
import { createFilter } from 'react-search-input';

import sportIcons from '../../files/sport-icons/icomoon/selection';

import IconCard from '../../components/iconCard';

import './iconSetGrid.scss';

const iconsSet = [];

sportIcons.icons
  .forEach((icon, index) => {
    iconsSet.push(
      {
        names: []
      }
    );
    icon.properties.name.split(', ').forEach(name => {
      iconsSet[index].names.push(`${sportIcons.preferences.fontPref.prefix}${name}`);
    })
  });

const IconSetGrid = props => {
  const {
    search,
  } = props;

  const sortedIcons = iconsSet
    .sort((a, b) => a.names.length - b.names.length > 0 ? 1 : a.names.length - b.names.length < 0 ? -1 : 0)
    .filter(createFilter(search, ['names']));

  return (
    <div className="icons-grid-holder">
      {sortedIcons.map((icon, index) => (
        <IconCard icon={icon} key={index} />
      ))}
    </div>
  );
};

export default IconSetGrid;
