import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import { onCopySuccess } from '../../helpers';
import { sportIconsCSSlink } from '../../constants';

import './importSection.scss';

const ImportSection = () => {
  return (
    <div className="import-section-holder">
      <div className="import-section">
        <p>You can import the Sport Icons to your project simply using this url:</p>
        <CopyToClipboard text={sportIconsCSSlink} onCopy={() => onCopySuccess(sportIconsCSSlink)}>
          <button>{sportIconsCSSlink.replace('https://', '')}</button>
        </CopyToClipboard>
      </div>
    </div>
  )
};

export default ImportSection;
