import React from 'react';
import SearchInput from 'react-search-input';

import './header.scss';
import './search.scss';

const Header = props => {
  const {
    setSearch,
  } = props;

  return (
    <header>
      <SearchInput className="search-input" onChange={(val) => setSearch(val)}/>
    </header>
  );
};

export default Header;
